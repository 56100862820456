<template>
	<div class="modal fade" id="taskShowModal" tabindex="-1" aria-labelledby="Информация о задаче" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content rounded-1 p-0">
				<div class="modal-header d-flex align-items-center border-0 p-20">
                    <div v-if="this.is_state_ready && !this.isEmpty(this.task)" class="d-flex align-items-start mb-1">
                        <sup v-if="this.task.is_cancel && this.inArray(event_type, ['call', 'show', 'meet', 'deal', 'even', 'pending_moderation']) && !task.is_completed"
																	  class="badge bg-danger text-white rounded-1 ms-2 fs-5 fw-normal me-auto" v-text="this.getCancelLabel(event_type)" />
																<input v-else v-show="this.inArray(event_type, ['call', 'meet', 'show', 'deal', 'even', 'pending_moderation'])"
																	   class="form-check-input rounded-1 ms-auto me-0 right-marning"
																	   type="checkbox"
																	   :id="'event_'+event_id"
																	   value="1"
																	   :checked="Boolean(task.is_completed)" @change="(event) => this.changeStatusEvent(event_id, !Boolean(task.is_completed))" />    
                    <span v-if="event_type == 'call'" class="font-semi fw-semi s-0o text-primary">Звонок</span>
																<span v-else-if="event_type == 'meet'" class="font-semi fw-semi ms-0 text-pink">Встреча</span>
																<span v-else-if="event_type == 'show'" class="font-semi fw-semi ms-0 text-fire">Показ</span>
																<span v-else-if="event_type == 'deal'" class="font-semi fw-semi ms-0 text-raspberry">Сделка</span>
																<span v-else-if="event_type == 'even'" class="font-semi fw-semi ms-0 text-ocean">{{ task.title }}</span>
																<span v-else class="font-semi fw-semi me-auto text-dark">Примечание</span>
                                                                
                    </div>
					
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body pt-0 px-20">
                    
					<div v-if="this.is_state_ready && !this.isEmpty(this.task)" class="d-flex flex-column flex-fill">
                        
                        <div v-if="event_type == 'call'" class="d-flex flex-column mb-3">
                        
                        <span class="fs-4 text-font-secondary mb-1">Позвонить {{ task.schedule_date_string }}</span>
                    </div>
                    <div v-else-if="event_type == 'meet'" class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-1">Запланирована встреча</span>
                        <span class="fs-4">на {{ task.event.schedule_date_string }}</span>
                    </div>
                    <div v-else-if="event_type == 'show'" class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-1">Запланирована показ</span>
                        <span class="fs-4">на {{ task.event.schedule_date_string }}</span>
                    </div>
                    <div v-else-if="event_type == 'deal'" class="d-flex flex-column mb-3">
                        <span class="fs-4 text-font-secondary mb-1">Запланирована сделка</span>
                        <span class="fs-4">на {{ task.event.schedule_date_string }}</span>
                    </div>
                    <div v-else-if="event_type == 'even'" class="d-flex flex-column mb-3">
                       
                        <span class="fs-4 text-font-secondary mb-1">Мероприятие состоится</span>
                        <span class="fs-4">{{ this.task.schedule_date_string }}</span>
                    </div>
						<div v-if="!this.isEmpty(this.task.comment)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Комментарий:</span>
							<span>{{ this.task.comment }}</span>
						</div>
						<div v-if="!this.isEmpty(this.task.address)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Адрес:</span>
							<span>{{ this.this.task.address }}</span>
						</div>
						<div v-if="!this.isEmpty(this.task.sum)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Сумма:</span>
							<span>{{ this.task.sum }}</span>
						</div>
						
					</div>
					<div v-else-if="this.isEmpty(this.task)" class="d-flex flex-column flex-fill align-items-center">
						<EmptyList message="Данные о задаче не найдены" />
					</div>
					<div v-else class="d-flex flex-column flex-fill align-items-center">
						<Loader />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../../services/CommonService";
	import api from "@/api";
	import Loader from "../common/Loader";
	import EmptyList from "../common/EmptyList";

	export default {
		name: 'TaskShowModal',
		components: {EmptyList, Loader},
		props: {
			fromSection: { type: String },
			injectData: { type: Object },
        },
        data() {
			return {
				task: {
					address: null,
                    comment: null,
                    is_cancel: false,
                    is_completed: false,
                    schedule_date: null,
                    schedule_date_string:null,
                    summ: 0,
                    time: null,
                    title: null 
				},
				modal: null,
				event_id: null,
				is_state_ready: false,
                event_type: null,
			}
        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
            inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			hideModal() {

				if (this.modal)
					this.modal.hide();

			},
			showModal() {

				if (this.modal)
					this.modal.show();

			},
			resetData() {
				this.task = {
					address: null,
                    comment: null,
                    is_cancel: false,
                    is_completed: false,
                    schedule_date: null,
                    schedule_date_string:null,
                    summ: 0,
                    time: null,
                    title: null
				};
				this.modal = null;
				this.event_id = null;
                this.event_ype = null;
				this.is_state_ready = false;
			},
			async getTask(event_id, event_type, section) {
			return api.get('/common/task', {
				params: {
					event_id: event_id ?? this.event_id,
					event_type: event_type ?? this.event_type,
					section: section ?? this.section,
				}
			})
			.then((response) => {
                
				CommonService.log('debug', 'getTask()::axios', {response: response.data});

				let event = [];
				if (response.status == 200 && response.data.success)
					event = response.data.task.event;

				this.item = event;
				return event;
			}).catch(function (error) {

				CommonService.log('error', 'getTask()::axios', error);

				return null;
			});
		},
        changeStatusEvent(event_id, state) {
				if (typeof event_id !== "undefined") {

					api.put('/common/task', {
						object_id: this.object_id,
						client_id: this.client_id,
						requisition_id: this.requisition_id,
						event_id: event_id,
						action: 'state',
						state: Number(state),
                        section: this.section
					}).then((response) => {
                        
						if (process.env.NODE_ENV == "development")
							console.debug('changeStatusEvent()::axios', {
								response: response.data
							});

					}).catch(function (error) {

						CommonService.log('error', 'changeStatusEvent()::axios', error);

					});
				}
			},
			async getPartnerEmployee(employee_id) {

				this.is_state_ready = false;

				return api.get('/partners/employee', {
					params: {
						id: employee_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartnerEmployee()::axios', {response: response.data});

					let employee = null;
					if (response.status == 200 && response.data.success)
						employee = response.data.item;

					return employee;
				}).catch(function (error) {

					CommonService.log('error', 'getPartnerEmployee()::axios', error);

				});
			},
		},
		mounted() {
			this.is_state_ready = false;
			this.modal = document.getElementById('taskShowModal');
			if (this.modal && typeof this.modal !== "undefined") {

				let _this = this;
				_this.modal.addEventListener('hidden.bs.modal', function (event) {
					_this.resetData();
					_this.emitter.emit("global.modalClose", this.modal);
				});

				_this.modal.addEventListener('shown.bs.modal', function (event) {

					_this.resetData();

					let target = event.relatedTarget;

					if (typeof target !== "undefined") {

						if (typeof target.getAttribute('data-bs-event-id') != "undefined")
							_this.event_id = target.getAttribute('data-bs-event-id');

					} else if (!_this.isEmpty(_this.injections)) {

						if (!_this.isEmpty(_this.injections.event_id))
							_this.event_id = _this.injections.event_id;
                        if (!_this.isEmpty(_this.injections.event_type))
							_this.event_type = _this.injections.event_type;

					}

					if (!_this.isEmpty(_this.section)) {
						if (_this.section == 'free' && !_this.isEmpty(_this.event_id)) {
                            console.log(_this.event_id, _this.event_type);
							_this.getTask(_this.event_id, _this.event_type, _this.section).then((item) => {
                                console.log(item);
								if (!_this.isEmpty(item))
									_this.task = {..._this.task, ...item};
								else
									_this.task = null;

								_this.is_state_ready = true;
							});
						} 
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('employeeInfoModal::show.bs.modal', {
							event_id: _this.event_id,
						});
					}

					_this.emitter.emit("global.modalShown", this.modal);
				});
			}
		},
		computed: {
			section() {
				return (typeof this.$props.fromSection !== "undefined") ? this.$props.fromSection : null;
			},
			injections() {
				return (typeof this.$props.injectData !== "undefined") ? this.$props.injectData : null;
			},
		},
    }
</script>

<style lang="scss">
    .right-marning {
        margin-right: 10px !important;
    }
</style>