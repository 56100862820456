<template>
    <ul class="m-0 p-0">
        <li
            v-for="(note, i) of this.$props.notes"
            :key="'note_' + i"

            class="m-0 d-flex align-items-start mb-20"
        >
            <span
                class="d-flex align-items-center justify-content-center border border-primary rounded-circle bg-white text-primary me-20"
                style="width: 40px; height: 40px;"
            >
                {{i + 1}}
            </span>

            <p
                class="fs-4 text-font-secondary m-0"
                style="width: calc(100% - 60px);"
            >

                {{ note }}
            </p>
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'Notes',

        props: {
            notes: { type: Array }
        }
    }
</script>