<template>
	<div class="modal fade" id="employeeInfoModal" tabindex="-1" aria-labelledby="Краткая информация о сотруднике партнёра" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content rounded-1 p-0">
				<div class="modal-header d-flex align-items-center border-0 p-20">
					<span v-if="this.section == 'partners'" class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
						Сотрудник партнёра
					</span>
					<span v-else class="modal-title fs-2 fw-semi font-semi lh-1" style="max-width: 90%;">
						Сотрудник агентства
					</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body pt-0 px-20">
					<div v-if="this.is_state_ready && !this.isEmpty(this.employee)" class="d-flex flex-column flex-fill">
						<div v-if="!this.isEmpty(this.employee.fullname)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Ф.И.О.:</span>
							<span>{{ this.employee.fullname }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.company)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Компания:</span>
							<span>{{ this.employee.company }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.phone)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Телефон:</span>
							<span>{{ this.employee.phone }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.email)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">E-mail:</span>
							<span>{{ this.employee.email }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.position)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Должность:</span>
							<span>{{ this.employee.position }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.birthday)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">День рождения:</span>
							<span>{{ this.employee.birthday }}</span>
						</div>
						<div v-if="!this.isEmpty(this.employee.instagram) || !this.isEmpty(this.employee.vkontakte) || !this.isEmpty(this.employee.telegram) || !this.isEmpty(this.employee.facebook) || !this.isEmpty(this.employee.twitter)" class="mb-3">
							<span class="d-block text-font-secondary d-block mb-1 fs-5">Социальные сети:</span>
							<a v-if="!this.isEmpty(this.employee.instagram)"
							   :href="this.employee.instagram"
							   target="_blank">
								Instagram
							</a>
							<a v-if="!this.isEmpty(this.employee.vkontakte)"
							   :href="this.employee.vkontakte"
							   target="_blank">
								вКонтакте
							</a>
							<a v-if="!this.isEmpty(this.employee.telegram)"
							   :href="this.employee.telegram"
							   target="_blank">
								Telegram
							</a>
							<a v-if="!this.isEmpty(this.employee.facebook)"
							   :href="this.employee.facebook"
							   target="_blank">
								Facebook
							</a>
							<a v-if="!this.isEmpty(this.employee.twitter)"
							   :href="this.employee.twitter"
							   target="_blank">
								Twitter
							</a>
						</div>
					</div>
					<div v-else-if="this.isEmpty(this.employee)" class="d-flex flex-column flex-fill align-items-center">
						<EmptyList message="Данные о сотруднике не найдены" />
					</div>
					<div v-else class="d-flex flex-column flex-fill align-items-center">
						<Loader />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../../services/CommonService";
	import api from "@/api";
	import Loader from "../common/Loader";
	import EmptyList from "../common/EmptyList";

	export default {
		name: 'EmployeeInfoModal',
		components: {EmptyList, Loader},
		props: {
			fromSection: { type: String },
			injectData: { type: Object },
        },
        data() {
			return {
				employee: {
					company: null,
					fullname: null,
					phone: null,
					email: null,
					position: null,
					birthday: null,
					instagram: null,
					vkontakte: null,
					telegram: null,
					facebook: null,
					twitter: null,
				},
				modal: null,
				employee_id: null,
				is_state_ready: false,
			}
        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			hideModal() {

				if (this.modal)
					this.modal.hide();

			},
			showModal() {

				if (this.modal)
					this.modal.show();

			},
			resetData() {
				this.employee = {
					company: null,
					fullname: null,
					phone: null,
					email: null,
					position: null,
					birthday: null,
					instagram: null,
					vkontakte: null,
					telegram: null,
					facebook: null,
					twitter: null,
				};
				this.modal = null;
				this.employee_id = null;
				this.is_state_ready = false;
			},
			async getEmployee(employee_id) {

				this.is_state_ready = false;

				return api.get('/user', {
					params: {
						id: employee_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getEmployee()::axios', {response: response.data});

					let employee = null;
					if (response.status == 200 && response.data.success)
						employee = response.data.item;

					return employee;
				}).catch(function (error) {

					CommonService.log('error', 'getEmployee()::axios', error);

				});
			},
			async getPartnerEmployee(employee_id) {

				this.is_state_ready = false;

				return api.get('/partners/employee', {
					params: {
						id: employee_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartnerEmployee()::axios', {response: response.data});

					let employee = null;
					if (response.status == 200 && response.data.success)
						employee = response.data.item;

					return employee;
				}).catch(function (error) {

					CommonService.log('error', 'getPartnerEmployee()::axios', error);

				});
			},
		},
		mounted() {
			this.is_state_ready = false;
			this.modal = document.getElementById('employeeInfoModal');
			if (this.modal && typeof this.modal !== "undefined") {

				let _this = this;
				_this.modal.addEventListener('hidden.bs.modal', function (event) {
					_this.resetData();
					_this.emitter.emit("global.modalClose", this.modal);
				});

				_this.modal.addEventListener('shown.bs.modal', function (event) {

					_this.resetData();

					let target = event.relatedTarget;

					if (typeof target !== "undefined") {

						if (typeof target.getAttribute('data-bs-employee-id') != "undefined")
							_this.employee_id = target.getAttribute('data-bs-employee-id');

					} else if (!_this.isEmpty(_this.injections)) {

						if (!_this.isEmpty(_this.injections.employee_id))
							_this.employee_id = _this.injections.employee_id;

					}

					if (!_this.isEmpty(_this.section)) {
						if (_this.section == 'users' && !_this.isEmpty(_this.employee_id)) {
							_this.getEmployee(_this.employee_id).then((item) => {

								if (!_this.isEmpty(item))
									_this.employee = {..._this.employee, ...item};
								else
									_this.employee = null;

								_this.is_state_ready = true;
							});
						} else if (_this.section == 'partners' && !_this.isEmpty(_this.employee_id)) {
							_this.getPartnerEmployee(_this.employee_id).then((item) => {

								if (!_this.isEmpty(item))
									_this.employee = {..._this.employee, ...item};
								else
									_this.employee = null;

								_this.is_state_ready = true;
							});
						}
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('employeeInfoModal::show.bs.modal', {
							employee_id: _this.employee_id,
						});
					}

					_this.emitter.emit("global.modalShown", this.modal);
				});
			}
		},
		computed: {
			section() {
				return (typeof this.$props.fromSection !== "undefined") ? this.$props.fromSection : null;
			},
			injections() {
				return (typeof this.$props.injectData !== "undefined") ? this.$props.injectData : null;
			},
		},
    }
</script>